import React from 'react';
import s from './WhyChooseUs.module.css';
import classNames from 'classnames';
import { FaAward, FaChartLine, FaClock, FaHandshake, FaTools } from 'react-icons/fa';
import { MdBuild } from 'react-icons/md';

const WhyChooseUs = () => {
    return (
      <section className={classNames(s.why_choose_us, s.container)} id='why-choose-us'>
        <h2>Why Choose Us</h2>
        <div className={s.why_choose_us_cards}>
          <div className={s.why_choose_us_card}>
            <FaAward className={s.why_choose_us_icon} />
            <h3>Experienced Team</h3>
            <p>Our team is composed of professionals with a deep understanding of the latest technologies and trends in web, app, and software development.</p>
          </div>
          <div className={s.why_choose_us_card}>
            <MdBuild className={s.why_choose_us_icon} />
            <h3>Client-Centric Approach</h3>
            <p>We work closely with you at every stage, from ideation to execution, ensuring your vision is brought to life.</p>
          </div>
          <div className={s.why_choose_us_card}>
            <FaChartLine className={s.why_choose_us_icon} />
            <h3>Quality Assurance</h3>
            <p>Rigorous testing and QA processes ensure that our products are not only functional but reliable and secure.</p>
          </div>
          <div className={s.why_choose_us_card}>
            <FaTools className={s.why_choose_us_icon} />
            <h3>Innovation & Creativity</h3>
            <p>We believe in pushing the boundaries of what’s possible, creating innovative solutions that meet and exceed expectations.</p>
          </div>
          <div className={s.why_choose_us_card}>
            <FaHandshake className={s.why_choose_us_icon} />
            <h3>Scalable Solutions</h3>
            <p>Our software and apps are designed to grow with your business, offering flexibility and scalability.</p>
          </div>
          <div className={s.why_choose_us_card}>
            <FaClock className={s.why_choose_us_icon} />
            <h3>On-Time Delivery</h3>
            <p>We understand the importance of deadlines and consistently deliver projects on time and within budget.</p>
          </div>
        </div>
      </section>
    );
  };
  
  export default WhyChooseUs;
