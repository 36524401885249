import React from 'react';
import s from './Hero.module.css';
import hero1 from '../../../../assets/hero-01.jpg';
import { FaQuoteLeft } from 'react-icons/fa';
import classNames from 'classnames';

const Hero = () => {
  return (
    <section className={classNames(s.hero, s.container)} id='home'>
      <div className={s.hero_content}>
        <h1>DIGITAL CRAFT FR</h1>
        <p>
          At DIGITAL CRAFT FR, we transform digital ideas into reality with cutting-edge web, app creation, and software development. Our innovative solutions empower businesses to thrive in the high-tech landscape.
        </p>
        <a href='#services' className={s.get_started_button}>Explore Our Services</a>
      </div>
      <div className={s.hero_image}>
        <img src={hero1} alt='Digital Solutions' />
        <div className={s.hero_image_text}>
          <FaQuoteLeft className={s.icon} />
          <p>
            Blending creativity, analytics, and technology to drive growth and innovation in the digital realm.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Hero;
