import React from 'react'
import s from './Testimonials.module.css'
import avt01 from '../../../../assets/avt01.png'
import avt02 from '../../../../assets/avt02.png'
import { FaStar } from 'react-icons/fa'

const testimonialsData = [
  {
    name:"David R.",
    title: "CEO, Swift Delivery Services",
    img: avt02,
    comment: "DIGITAL CRAFT FR Innovations exceeded our expectations. Their team delivered a highly functional and visually stunning mobile app for our business, boosting our customer engagement."
  },{
    name: "Julia M.", 
    title: "CTO, Smart Solution", 
    img: avt01,
    comment: "Working with DIGITAL CRAFT FR has been a fantastic experience. Their development team is highly skilled and was able to tailor the software exactly to our needs, improving our operational efficiency."
  },{
    name: "Sarah K.", 
    title: "Marketing Director, Bright Enterprises", 
    img: avt01,
    comment: "Our new website has completely transformed our online presence. The design is sleek, and the functionality is seamless. DIGITAL CRAFT FR delivered on every promise."
  }
]

const Testimonials = () => {
  return (
    <div className={s.testimonials} id="testimonials">
      <h2 className={s.main_title}>Testimonials</h2>
      <div className={s.container}>
        {testimonialsData.map(item=>(
          <div className={s.box} key={item.name}>
            <div className={s.image}>
              <img src={item.img} alt="avatar" />
            </div>
            <h3>{item.name}</h3>
            <span className={s.title}>{item.title}</span>
            <div className={s.rate}>
                <FaStar className={s.filled} />
                <FaStar className={s.filled} />
                <FaStar className={s.filled} />
                <FaStar className={s.filled} />
                <FaStar className={s.filled} />
            </div>
            <p>{item.comment}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Testimonials