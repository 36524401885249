import React from 'react'
import MainLayout from '../../components/MainLayout'
import Hero from './components/hero/Hero'
import Services from './components/services/Services'
import WhoWeAre from './components/whoWeAre/WhoWeAre'
import WhyChooseUs from './components/whyChooseUs/WhyChooseUs'
import Testimonials from './components/testimonials/Testimonials'
import ContactUs from './components/contactUs/ContactUs'

const Home = () => {
  return (
    <MainLayout>
      <Hero />
      <Services />
      <WhoWeAre />
      <WhyChooseUs />
      <Testimonials />
      <ContactUs />
    </MainLayout>
  )
}

export default Home