import React from 'react';
import classNames from 'classnames';
import s from './WhoWeAre.module.css';
import whoWeAre1 from '../../../../assets/who-we-are-01.jpg';

const WhoWeAre = () => {
  return (
    <section className={classNames(s.who_we_are, s.container)} id='about'>
      <div className={s.who_we_are_content}>
        <h2>Who We Are</h2>
        <p>
          At <strong>DIGITAL CRAFT FR</strong>, we are dedicated to transforming digital ideas into reality. Our team of seasoned developers, designers, and strategists is committed to helping businesses of all sizes reach their full potential through innovative web, app, and software solutions. We combine creativity, technology, and data-driven strategies to drive growth and enhance digital presence, always focusing on achieving measurable results.
        </p>
        <p>
          Our client-centric approach means we tailor our services to meet the specific needs of each business. Whether you're an established company looking to innovate or a startup aiming to make a significant impact, <strong>DIGITAL CRAFT FR</strong> is your ideal partner for achieving long-term success.
        </p>
        <button className={s.get_started_button}>Get Started</button>
      </div>
      <div className={s.who_we_are_images}>
        <img src={whoWeAre1} alt='Team working' className={s.who_we_are_image} />
      </div>
    </section>
  );
};

export default WhoWeAre;
