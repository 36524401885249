import React from 'react';
import s from './Footer.module.css';
import classNames from 'classnames';
import { CiFacebook, CiTwitter, CiLinkedin } from 'react-icons/ci';

const Footer = () => {
  return (
    <footer className={s.footer}>
      <div className={classNames(s.container, s.footer_content)}>
        <div className={s.footer_section}>
          <h3>About Us</h3>
          <p>
            DIGITAL CRAFT FR specializes in high-tech, IA, and software development, providing cutting-edge digital solutions to drive business innovation.
          </p>
          <br/>
          <p>Reg number: 932 337 223</p>
        </div>
        <div className={s.footer_section}>
          <h3>Contact</h3>
          <p>Address: 45 Rue Victor Hugo, Pamier, 09100</p>
          <p>Email: digitalcraftfr1@gmail.com</p>
        </div>
        <div className={s.footer_section}>
          <h3>Follow Us</h3>
          <div className={s.social_icons}>
            <a href='https://facebook.com' target='_blank' rel='noopener noreferrer'>
              <CiFacebook />
            </a>
            <a href='https://twitter.com' target='_blank' rel='noopener noreferrer'>
              <CiTwitter />
            </a>
            <a href='https://linkedin.com' target='_blank' rel='noopener noreferrer'>
              <CiLinkedin />
            </a>
          </div>
        </div>
      </div>
      <div className={s.footer_bottom}>
        <p>&copy; 2024 DIGITAL CRAFT FR. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
