import React from 'react';
import { LuMail } from 'react-icons/lu';
import { SlLocationPin } from 'react-icons/sl';
import { BsTelephone } from "react-icons/bs";
import s from './ContactUs.module.css'; 
import classNames from 'classnames';

const ContactUs = () => {
  const handleSubmit = e => {
    e.preventDefault();
    console.log('Form submitted');
  };

  return (
    <section className={classNames(s.contact_us, s.container)} id='contact'>
      <div className={s.left}>
        <h2>Contact Us</h2>
        <p>We'd love to hear from you! Reach out to us via email or fill out the form below, and we'll get back to you as soon as possible.</p>
        <div className={s.block}>
          <LuMail className={s.icon} />
          <a className={s.mail} href='mailto:digitalcraftfr1@gmail.com'>
            digitalcraftfr1@gmail.com
          </a>
        </div>
        <div className={s.block}>
          <SlLocationPin className={s.icon} />
          <p className={s.address}>
            45 Rue Victor Hugo, Pamier, 09100, France
          </p>
        </div>
        <div className={s.block}>
          <BsTelephone className={s.icon} />
          <p className={s.phone}>
            0618431520
          </p>
        </div>
      </div>
      <div className={s.right}>
        <h2>Send Us a Message</h2>
        <div className={s.contact_form_container}>
          <form className={s.contact_form} onSubmit={handleSubmit}>
            <div className={s.form_group}>
              <label htmlFor='name'>Name</label>
              <input type='text' id='name' name='name' required />
            </div>
            <div className={s.form_group}>
              <label htmlFor='email'>Email</label>
              <input type='email' id='email' name='email' required />
            </div>
            <div className={s.form_group}>
              <label htmlFor='message'>Message</label>
              <textarea id='message' name='message' rows='5' required></textarea>
            </div>
            <button type='submit' className={s.submit_button}>
              Submit
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
