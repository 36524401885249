import React, { useState } from 'react';
import s from './Header.module.css';
import classNames from 'classnames';
import { FaBars, FaTimes } from 'react-icons/fa';

const Header = () => {
  const [isMobile, setIsMobile] = useState(false);

  return (
    <header className={s.header}>
      <div className={classNames(s.container, s.header_wraper)}>
        <a className={s.logo} href='/'>
          DIGITAL CRAFT FR
        </a>
        <nav className={isMobile ? s.nav_links_mobile : s.nav_links}>
          <a href='#home' className={s.nav_link}>Home</a>
          <a href='#services' className={s.nav_link}>Services</a>
          <a href='#about' className={s.nav_link}>About Us</a>
          <a href='#contact' className={s.nav_link}>Contact</a>
          <a href='#contact' className={s.free_quote_button}>Get a Quote</a>
        </nav>
        <button
          className={s.mobile_menu_icon}
          onClick={() => setIsMobile(!isMobile)}>
          {isMobile ? <FaTimes /> : <FaBars />}
        </button>
      </div>
    </header>
  );
};

export default Header;
