import React from 'react';
import s from './Services.module.css';
import { MdOutlineWeb, MdOutlineApps } from 'react-icons/md';
import { FaCloud, FaMobileAlt, FaCode } from 'react-icons/fa';
import { BiSupport } from 'react-icons/bi';
import classNames from 'classnames';

const Services = () => {
  return (
    <section className={classNames(s.services, s.container)} id='services'>
      <h2>Our Services</h2>
      <div className={s.service_cards}>
        <div className={s.service_card}>
          <MdOutlineWeb className={s.icon} />
          <h3>Custom Web Development</h3>
          <ul>
            <li>Tailor-made websites that are responsive, scalable, and optimized for performance.</li>
            <li>Creating seamless user experiences with cutting-edge web technologies.</li>
          </ul>
        </div>
        <div className={s.service_card}>
          <MdOutlineApps className={s.icon} />
          <h3>Mobile App Development</h3>
          <ul>
            <li><strong>Cross-Platform Apps:</strong> Building apps that perform well across iOS and Android.</li>
            <li><strong>Native Apps:</strong> Developing high-performance native apps for enhanced functionality.</li>
          </ul>
        </div>
        <div className={s.service_card}>
          <FaCloud className={s.icon} />
          <h3>Cloud Solutions</h3>
          <ul>
            <li><strong>Cloud-Based Applications:</strong> Implementing scalable and efficient cloud infrastructures.</li>
            <li><strong>Integration:</strong> Integrating cloud services to enhance business operations and flexibility.</li>
          </ul>
        </div>
        <div className={s.service_card}>
          <FaCode className={s.icon} />
          <h3>Software Solutions</h3>
          <ul>
            <li>Custom software development to tackle unique business challenges.</li>
            <li>Streamlining operations with tailored software solutions.</li>
          </ul>
        </div>
        <div className={s.service_card}>
          <FaMobileAlt className={s.icon} />
          <h3>API Development & Integration</h3>
          <ul>
            <li>Seamless API integration for improved interoperability across platforms.</li>
            <li>Developing custom APIs to meet specific business needs.</li>
          </ul>
        </div>
        <div className={s.service_card}>
          <BiSupport className={s.icon} />
          <h3>Consulting & Training</h3>
          <ul>
            <li>Providing expert consulting to address technology and software challenges.</li>
            <li>Offering workshops and training sessions to upskill your team.</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Services;
